<template>
    <section class="new_css no-print">
        <!--工具条-->
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0"
                    :span="24"
                    class="organizetoolbar"
                    style="padding-bottom: 0px">
                <el-form :inline="true"
                         ref="selectForm"
                         :model="selectForm"
                         class="select_form">
                    <el-form-item label="应聘日期:">
                        <el-col :span="11">
                            <el-date-picker type="date"
                                            placeholder="选择日期"
                                            v-model="selectForm.dateStart"
                                            value-format="yyyy-MM-dd"
                                            style="width: 100%"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-date-picker type="date"
                                            placeholder="选择日期"
                                            v-model="selectForm.dateEnd"
                                            value-format="yyyy-MM-dd"
                                            style="width: 100%"></el-date-picker>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="姓名:">
                        <el-input v-model="selectForm.name" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证:">
                        <el-input v-model="selectForm.idCard"
                                  placeholder="身份证"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList"
                             @callFunction="callFunction"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <!-- <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar> -->
        <!--列表-->
        <div class="calc-height">
            <el-table :data="users"
                      highlight-current-row
                      height="100%"
                      @current-change="selectCurrentRow"
                      @row-dblclick="handleEdit"
                      class="new_table">
                <el-table-column prop="Name" label="姓名"> </el-table-column>
                <el-table-column label="应聘日期"
                                 prop="ApplyDate"
                                 :formatter="formatStartTime"></el-table-column>
                <el-table-column label="性别" prop="Sex"></el-table-column>
                <el-table-column label="出生日期"
                                 prop="Birthday"
                                 :formatter="formatStartTime"></el-table-column>
                <el-table-column prop="IDCard" show-overflow-tooltip label="身份证">
                </el-table-column>
                <el-table-column prop="NativePlace" show-overflow-tooltip label="籍贯">
                </el-table-column>
                <el-table-column prop="PoliticsStatus" label="政治面貌">
                </el-table-column>
                <el-table-column prop="Nationality" label="民族"> </el-table-column>
                <el-table-column prop="NativeStatus" label="户口性质">
                </el-table-column>
                <el-table-column prop="MobilePhone" label="联系方式"> </el-table-column>
                <el-table-column prop="ApplySource" label="招聘来源"> </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper,total"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>
        <el-dialog title="详细信息"
                   :visible.sync="userDetailVisible"
                   v-model="userDetailVisible"
                   :close-on-click-modal="false"
                   width="80%">
            <el-tabs type="border-card">
                <!-- <el-button type="primary" v-print="printObj">直接打印</el-button> -->
                <el-tab-pane>
                    <span slot="label" ref="print"> 人员详情信息</span>
                    <div id="printMe" ref="printContent">
                        <el-form ref="userDetail"
                                 label-width="100px"
                                 :model="userDetail"
                                 size="mini">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="ApplyDate" label="填表日期">
                                        <el-date-picker type="date"
                                                        v-model="userDetail.ApplyDate"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="头像" style="text-align: right">
                                        <el-upload class="avatar-uploader"
                                                   :headers ="token"
                                                   action="https://api.gradgroup.cn/ftp/ftp/upload"
                                                   :show-file-list="false"
                                                   :on-success="OSOnSuccess">
                                            <img v-if="userDetail.Image_URL"
                                                 v-lazy="userDetail.Image_URL"
                                                 class="avatar" />
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="姓名:" prop="Name">
                                        <el-input v-model="userDetail.Name"
                                                  :disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="性别:">
                                        <el-radio-group v-model="userDetail.Sex">
                                            <el-radio label="男"></el-radio>
                                            <el-radio label="女"></el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="出生年月:" prop="Birthday">
                                        <el-date-picker type="date"
                                                        v-model="userDetail.Birthday"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="选择出生年月"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="籍贯:" prop="NativePlace">
                                        <el-input v-model="userDetail.NativePlace"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="民族:" prop="Nationality">
                                        <el-input v-model="userDetail.Nationality"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="婚姻状况:" prop="MarriedStatus">
                                        <el-select v-model="userDetail.MarriedStatus"
                                                   placeholder="选择婚姻状况">
                                            <el-option v-for="item in qyTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="全日制学历 :" prop="FirstDiploma">
                                        <el-select v-model="userDetail.FirstDiploma"
                                                   placeholder="选择第一学历">
                                            <el-option v-for="item in edTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="职称:" prop="Position">
                                        <el-select v-model="userDetail.Position"
                                                   placeholder="选择职称">
                                            <el-option v-for="item in tiTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="外语语种及级别:">
                                        <el-input v-model="userDetail.LanguageLevel"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="6">
                                    <el-form-item label="非全日制学历:" prop="TopDiploma">
                                        <el-select v-model="userDetail.TopDiploma"
                                                   placeholder="选择非全日制学历">
                                            <el-option v-for="item in edTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="手机号码:" prop="MobilePhone">
                                        <el-input v-model="userDetail.MobilePhone"
                                                  maxlength="11"
                                                  show-word-limit
                                                  placeholder="请输入有效手机号"
                                                  @blur="phonecheck"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="政治面貌:" prop="PoliticsStatus">
                                        <el-select v-model="userDetail.PoliticsStatus"
                                                   placeholder="选择政治面貌">
                                            <el-option v-for="item in poTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="户口性质:" prop="NativeStatus">
                                        <el-select v-model="userDetail.NativeStatus"
                                                   placeholder="选择户口性质">
                                            <el-option v-for="item in adTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="身份证号:" prop="IDCard">
                                        <el-input v-model="userDetail.IDCard"
                                                  :disabled="true"
                                                  maxlength="18"
                                                  show-word-limit
                                                  placeholder="请输入有效身份证号"
                                                  @blur="nocheck"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="户籍地址:" prop="IDAddress">
                                        <el-input v-model="userDetail.IDAddress"
                                                  placeholder="请输入户籍地址"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="电子邮箱:">
                                        <el-input v-model="userDetail.Email"
                                                  placeholder="请输入电子邮箱"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="通讯地址:" prop="Address">
                                        <el-input v-model="userDetail.Address"
                                                  placeholder="请输入通讯地址"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="招聘来源:">
                                        <el-select v-model="userDetail.ApplySource"
                                                   placeholder="选择招聘来源">
                                            <el-option v-for="item in applyTypeOptions"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="紧急联系人/姓名:" label-width="140px">
                                        <el-input v-model="userDetail.Emergency"
                                                  placeholder="请输入紧急联系人/姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="紧急联系人/电话:" label-width="140px">
                                        <el-input v-model="userDetail.EmergencyPhone"
                                                  placeholder="请输入紧急联系人/电话"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="应聘岗位:">
                                        <el-input v-model="userDetail.ApplyJob"
                                                  placeholder="请输入应聘岗位"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="可开始工作时间:" label-width="140px">
                                        <el-date-picker type="date"
                                                        v-model="userDetail.ApplyStartDate"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="选择可开始工作时间"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="inp-con" :span="8">
                                    <el-form-item label="介绍人:">
                                        <el-input v-model="userDetail.IntroducerName"
                                                  placeholder="介绍人"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="intro">
                                        <p>健康调查<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-table :data="userDetail.DiseaseArr"
                                          style="width: 100%"
                                          class="bg-color">
                                    <el-table-column prop="DiseaseType"
                                                     label="病史类型"></el-table-column>
                                    <el-table-column prop="IsYes" label="状态"></el-table-column>
                                    <el-table-column prop="Explain"
                                                     label="说明"></el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>学习经历<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                                <el-col :span="12"
                                        class="toolbar"
                                        style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn"
                                                       type="primary"
                                                       v-on:click="customerhandleAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="userDetail.EducatedArr" style="width: 100%">
                                    <el-table-column prop="EnterTime"
                                                     label="开始时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="GraduateTime"
                                                     label="结束时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="GradauteSchool"
                                                     label="学校名称"></el-table-column>
                                    <el-table-column prop="Speciality"
                                                     label="专业"></el-table-column>
                                    <el-table-column prop="Certificate"
                                                     label="学历"></el-table-column>
                                    <el-table-column prop="Voucher"
                                                     label="证明人及电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="CustomerhandleDetail(scope.row, scope.$index)"
                                                       type="text"
                                                       size="small">修改</el-button>
                                            <el-button size="small"
                                                       v-on:click="CustomerhandleDelete(scope.row)"
                                                       type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>

                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>培训经历</p>
                                    </div>
                                </el-col>
                                <el-col :span="12"
                                        class="toolbar"
                                        style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn"
                                                       type="primary"
                                                       v-on:click="trainAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="userDetail.TrainedArr" style="width: 100%">
                                    <el-table-column prop="StartTime"
                                                     label="开始时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="EndTime"
                                                     label="结束时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="Institutions"
                                                     label="培训机构"></el-table-column>
                                    <el-table-column prop="Details"
                                                     label="培训内容"></el-table-column>
                                    <el-table-column prop="InCertificate"
                                                     label="证书名称"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="
                          TrainingExperienceDetail(scope.row, scope.$index)
                        "
                                                       type="text"
                                                       size="small">修改</el-button>
                                            <el-button size="small"
                                                       v-on:click="TrainingExperienceDelete(scope.row)"
                                                       type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-row>
                                    <el-col :span="12" class="list-title">
                                        <div class="intro">
                                            <p>工作经历<span style="color: red">*</span></p>
                                        </div>
                                    </el-col>
                                    <el-col :span="12"
                                            class="toolbar"
                                            style="padding-bottom: 0px; text-align: right">
                                        <el-form :inline="true">
                                            <el-form-item>
                                                <el-button class="add-btn"
                                                           type="primary"
                                                           v-on:click="workAdd">新增</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                            </el-row>
                            <el-row>
                                <el-table :data="userDetail.WorkedArr" style="width: 100%">
                                    <el-table-column prop="StartTime"
                                                     label="开始时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="EndTime"
                                                     label="结束时间"
                                                     :formatter="formatStartTime"></el-table-column>
                                    <el-table-column prop="WorkAddress"
                                                     label="工作单位"></el-table-column>
                                    <el-table-column prop="LeavingReason"
                                                     label="职位/岗位"></el-table-column>
                                    <el-table-column prop="BecaseWork"
                                                     label="离职原因"></el-table-column>
                                    <el-table-column prop="WorkVoucher"
                                                     label="证明人及电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="WorkExperienceDetail(scope.row, scope.$index)"
                                                       type="text"
                                                       size="small">修改</el-button>
                                            <el-button size="small"
                                                       v-on:click="WorkExperienceDelete(scope.row)"
                                                       type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="12" class="list-title">
                                    <div class="intro">
                                        <p>家庭成员<span style="color: red">*</span></p>
                                    </div>
                                </el-col>
                                <el-col :span="12"
                                        class="toolbar"
                                        style="padding-bottom: 0px; text-align: right">
                                    <el-form :inline="true">
                                        <el-form-item>
                                            <el-button class="add-btn"
                                                       type="primary"
                                                       v-on:click="familyAdd">新增</el-button>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-table :data="userDetail.FamilyArr" style="width: 100%">
                                    <el-table-column prop="FaName" label="姓名"></el-table-column>
                                    <el-table-column prop="Relationship"
                                                     label="与本人关系"></el-table-column>
                                    <el-table-column prop="WorkDpt"
                                                     label="职位/岗位"></el-table-column>
                                    <el-table-column prop="WorkUnits"
                                                     label="工作单位"></el-table-column>
                                    <el-table-column prop="PhoneNo"
                                                     label="联系电话"></el-table-column>
                                    <el-table-column label="操作" width="150">
                                        <template scope="scope">
                                            <el-button @click="FamilyDetail(scope.row, scope.$index)"
                                                       type="text"
                                                       size="small">修改</el-button>
                                            <el-button size="small"
                                                       v-on:click="FamilyDelete(scope.row)"
                                                       type="text">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <span>备注:</span><el-input type="textarea"
                                                              v-model="userDetail.Remark"></el-input>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" style="text-align: center">
                                    <el-form-item>
                                        <el-button type="primary" @click="submitForm('userDetail')">确认修改</el-button>
                                        <el-button @click="resetForm('userDetail')">取消</el-button>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="面试记录">
                    <el-table :data="InterviewRecord"
                              highlight-current-row
                              style="width: 100%">
                        <el-table-column prop="Factory" label="面试公司"> </el-table-column>
                        <el-table-column label="面试时间"
                                         prop="TalkDate"
                                         :formatter="formatStartTime"></el-table-column>
                        <el-table-column label="面试岗位" prop="Post"></el-table-column>
                        <el-table-column prop="IsPassed" label="是否通过">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
        <el-dialog title="面试"
                   :visible.sync="interviewVisible"
                   v-model="interviewVisible"
                   :close-on-click-modal="false"
                   width="60%">
            <el-form :rules="rules"
                     ref="InterviewForm"
                     :model="InterviewForm"
                     class="demo-form-inline"
                     label-width="30%">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="面试公司:" prop="factory" rules="[{ required: true, message: '请填写姓名', trigger: 'blur' }]">
                            <el-select v-model="InterviewForm.factory"
                                       @change="selectModel($event)"
                                       filterable
                                       placeholder="面试公司">
                                <el-option v-for="item in OrganizeLists"
                                           :key="item.deptId"
                                           :label="item.deptName"
                                           :value="item.deptName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="面试岗位:" prop="post">
                            <el-input v-model="InterviewForm.post"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="岗位类型:" prop="PostType">
                            <el-select v-model="InterviewForm.PostType"
                                       placeholder="岗位类型">
                                <el-option v-for="item in positionLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item prop="talkDate" label="面试时间">
                            <el-date-picker type="date"
                                            v-model="InterviewForm.talkDate"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="面试人员:" prop="TalkPersons">
                            <el-input type="text"
                                      :disabled="true"
                                      v-model="InterviewForm.TalkPersons">
                                <el-button slot="append"
                                           icon="el-icon-search"
                                           @click="chooseUser"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="基础能力:" prop="BasicAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.BasicAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.BasicAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业知识技能:" prop="ProfessionalAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.ProfessionalAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.ProfessionalAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="相关工作经验:" prop="WorkAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.WorkAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.WorkAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="办公软件使用能力:" prop="OfficeAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.OfficeAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.OfficeAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="沟通能力表达:" prop="CommunicateAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.CommunicateAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.CommunicateAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业软件使用能力:" prop="ProfessionalSoftware">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.ProfessionalSoftwareExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.ProfessionalSoftware"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发展潜力:" prop="PotentialAbility">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.PotentialAbilityExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.PotentialAbility"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="仪容仪表:" prop="DressUp">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.DressUpExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.DressUp"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="价值观/态度:" prop="Attitude">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.AttitudeExp"
                                      class="input-with-select">
                                <el-select v-model="InterviewForm.Attitude"
                                           slot="prepend"
                                           style="width: 80px"
                                           placeholder="选择">
                                    <el-option v-for="item in BasicLists"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="其他:">
                            <el-input placeholder="请输入内容"
                                      v-model="InterviewForm.Remark"
                                      class="input-with-select">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="整体匹配度:">
                            <el-select v-model="InterviewForm.MatchRate"
                                       placeholder="整体匹配度">
                                <el-option v-for="item in matchRateLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建议:">
                            <el-select v-model="InterviewForm.Recommendation"
                                       placeholder="建议">
                                <el-option v-for="item in RecommendationLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="面试结果:" prop="isPassed">
                            <el-select v-model="InterviewForm.isPassed"
                                       placeholder="面试结果">
                                <el-option v-for="item in passedLists"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="前公司薪资待遇:" prop="OldFactorySalary"
                                      :rules="[{ required: true, message: '请填写前公司薪资待遇', trigger: 'blur' },]">
                            <el-input type="text" v-model="InterviewForm.OldFactorySalary" @blur="inputMoney('OldFactorySalary')" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="面试总结:" prop="Summarize" label-width="15%">
                        <el-input type="textarea"
                                  v-model="InterviewForm.Summarize"></el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="前公司福利待遇:" prop="OldFactoryWelfare" label-width="15%">
                        <el-input placeholder="请输入内容" type="textarea"
                                  v-model="InterviewForm.OldFactoryWelfare">
                        </el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="前公司名称:" prop="OldFactory" label-width="15%">
                        <el-input placeholder="请输入内容" type="textarea"
                                  v-model="InterviewForm.OldFactory">
                        </el-input>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :span="24" style="text-align: center">

                        <el-button type="primary" v-on:click="onSubmit('InterviewForm')">提交</el-button>

                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="addUserVisible"
                   v-model="addUserVisible"
                   width="75%"
                   center
                   append-to-body>
            <UserChoose :data="choosedusers"
                        :all="true"
                        :single="false"
                        @callback="usercallFunction"></UserChoose>
        </el-dialog>
        <el-dialog :visible.sync="UserVisible"
                   v-model="UserVisible"
                   width="75%"
                   center
                   append-to-body>
            <el-button type="primary"
                       size="mini"
                       icon="el-icon-document"
                       class="no-print"
                       @click="print()">打印</el-button>
            <div class="grid-content bg-purple" ref="print">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    填单日期: {{ dataForm.ApplyDate }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">姓名: {{ dataForm.Name }}</div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    出生年月: {{ dataForm.Birthday }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    住址电话: {{ dataForm.MobilePhone }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">性别: {{ dataForm.Sex }}</div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    民族: {{ dataForm.Nationality }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    全日制学历: {{ dataForm.FirstDiploma }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">职称: {{ dataForm.Position }}</div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    外语级别: {{ dataForm.LanguageLevel }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">健康状况: {{ dataForm.Health }}</div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    非全日制学历: {{ dataForm.TopDiploma }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    手机号码: {{ dataForm.MobilePhone }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    政治面貌: {{ dataForm.PoliticsStatus }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    户口性质: {{ dataForm.NativeStatus }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    籍贯: {{ dataForm.NativePlace }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>

                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">身份证号: {{ dataForm.IDCard }}</div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    婚姻状况: {{ dataForm.MarriedStatus }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">电子邮箱: {{ dataForm.Email }}</div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    户籍地址: {{ dataForm.IDAddress }}
                                </div>
                            </div>
                        </el-col>

                        <el-col :span="12">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    通讯地址: {{ dataForm.Address }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    招聘来源: {{ dataForm.ApplySource }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    紧急联系人/姓名: {{ dataForm.Connect_UserName }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    紧急联系人/电话: {{ dataForm.EmergencyPhone }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    应聘岗位: {{ dataForm.ApplyJob }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    可开始工作时间: {{ dataForm.ApplyStartDate }}
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="personal-relation">
                                <div class="relation-item">
                                    介绍人: {{ dataForm.IntroducerName }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>健康调查</span>
                    </div>
                    <el-row>
                        <el-table :data="dataForm.DiseaseArr" style="width: 60%">
                            <el-table-column prop="DiseaseType"
                                             label="病史类型"></el-table-column>
                            <el-table-column prop="IsYes" label="状态"></el-table-column>
                            <el-table-column prop="Explain" label="说明"></el-table-column>
                        </el-table>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>学习经历(高中,大学，在职进修)</span>
                    </div>
                    <el-row>
                        <el-table :data="dataForm.EducatedArr" style="width: 100%">
                            <el-table-column prop="EnterTime"
                                             label="开始时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="GraduateTime"
                                             label="结束时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="GradauteSchool"
                                             label="学校名称"></el-table-column>
                            <el-table-column prop="Speciality" label="专业"></el-table-column>
                            <el-table-column prop="Certificate"
                                             label="学历"></el-table-column>
                            <el-table-column prop="Voucher"
                                             label="证明人及电话"></el-table-column>
                        </el-table>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>培训经历</span>
                    </div>
                    <el-row>
                        <el-table :data="dataForm.TrainedArr" style="width: 100%">
                            <el-table-column prop="StartTime"
                                             label="开始时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="EndTime"
                                             label="结束时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="Institutions"
                                             label="培训机构"></el-table-column>
                            <el-table-column prop="Details"
                                             label="培训内容"></el-table-column>
                            <el-table-column prop="InCertificate"
                                             label="证书名称"></el-table-column>
                        </el-table>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>工作经历</span>
                    </div>
                    <el-row>
                        <el-table :data="dataForm.WorkedArr" style="width: 100%">
                            <el-table-column prop="StartTime"
                                             label="开始时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="EndTime"
                                             label="结束时间"
                                             :formatter="formatStartTime"></el-table-column>
                            <el-table-column prop="WorkAddress"
                                             label="工作单位"></el-table-column>
                            <el-table-column prop="LeavingReason"
                                             label="职位/岗位"></el-table-column>
                            <el-table-column prop="BecaseWork"
                                             label="离职原因"></el-table-column>
                            <el-table-column prop="WorkVoucher"
                                             label="证明人及电话"></el-table-column>
                        </el-table>
                    </el-row>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>家庭成员</span>
                    </div>
                    <el-row>
                        <el-table :data="dataForm.FamilyArr" style="width: 100%">
                            <el-table-column prop="FaName" label="姓名"></el-table-column>
                            <el-table-column prop="Relationship"
                                             label="与本人关系"></el-table-column>
                            <el-table-column prop="WorkDpt"
                                             label="职位/岗位"></el-table-column>
                            <el-table-column prop="WorkUnits"
                                             label="工作单位"></el-table-column>
                            <el-table-column prop="PhoneNo" label="联系电话"></el-table-column>=
                        </el-table>
                    </el-row>
                </el-card>
            </div>
        </el-dialog>
        <el-dialog title="学习经历"
                   :visible.sync="CustomeraddFormVisible"
                   v-model="CustomeraddFormVisible"
                   :close-on-click-modal="false">
            <el-form label-width="150px"
                     ref="CustomeraddForm"
                     :model="CustomeraddForm">
                <el-form-item label="开始时间:" prop="EnterTime">
                    <el-date-picker v-model="CustomeraddForm.EnterTime"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:" prop="GraduateTime">
                    <el-date-picker v-model="CustomeraddForm.GraduateTime"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="学校名称:" prop="GradauteSchool">
                    <el-input v-model="CustomeraddForm.GradauteSchool"></el-input>
                </el-form-item>
                <el-form-item label="专业:">
                    <el-input v-model="CustomeraddForm.Speciality"></el-input>
                </el-form-item>
                <el-form-item label="学历:">
                    <el-input v-model="CustomeraddForm.Certificate"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话">
                    <el-input v-model="CustomeraddForm.Voucher"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="CustomeraddFormVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="CustomeraddSubmit('CustomeraddForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="培训经历"
                   :visible.sync="TrainingExperienceaddFormVisible"
                   v-model="TrainingExperienceaddFormVisible"
                   :close-on-click-modal="false">
            <el-form label-width="150px" ref="TrainaddForm" :model="TrainaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="TrainaddForm.StartTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="TrainaddForm.EndTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="培训机构:">
                    <el-input v-model="TrainaddForm.Institutions"></el-input>
                </el-form-item>
                <el-form-item label="培训内容:">
                    <el-input type="textarea" v-model="TrainaddForm.Details"></el-input>
                </el-form-item>
                <el-form-item label="证书名称:">
                    <el-input v-model="TrainaddForm.InCertificate"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="TrainingExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="TrainaddSubmit('TrainaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="工作经历"
                   :visible.sync="WorkExperienceaddFormVisible"
                   v-model="WorkExperienceaddFormVisible"
                   :close-on-click-modal="false">
            <el-form label-width="150px" ref="WorkaddForm" :model="WorkaddForm">
                <el-form-item label="开始时间:">
                    <el-date-picker v-model="WorkaddForm.StartTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间:">
                    <el-date-picker v-model="WorkaddForm.EndTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="工作单位:">
                    <el-input v-model="WorkaddForm.WorkAddress"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:">
                    <el-input v-model="WorkaddForm.LeavingReason"></el-input>
                </el-form-item>
                <el-form-item label="离职原因:">
                    <el-input type="textarea" v-model="WorkaddForm.BecaseWork"></el-input>
                </el-form-item>
                <el-form-item label="证明人及电话:">
                    <el-input v-model="WorkaddForm.WorkVoucher"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button v-on:click="WorkExperienceaddFormVisible = false">取消</el-button>
                    <el-button type="primary" v-on:click="WorkaddSubmit('WorkaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="家庭成员"
                   :visible.sync="FamilyaddFormVisible"
                   v-model="FamilyaddFormVisible"
                   :close-on-click-modal="false">
            <el-form label-width="150px" ref="FamilyaddForm" :model="FamilyaddForm">
                <el-form-item label="姓名:" prop="FaName">
                    <el-input v-model="FamilyaddForm.FaName"></el-input>
                </el-form-item>
                <el-form-item label="与本人关系:" prop="Relationship">
                    <el-input v-model="FamilyaddForm.Relationship"></el-input>
                </el-form-item>
                <el-form-item label="职位/岗位:" prop="FaAge">
                    <el-input v-model="FamilyaddForm.WorkDpt"></el-input>
                </el-form-item>
                <el-form-item label="工作单位:" prop="WorkUnits">
                    <el-input v-model="FamilyaddForm.WorkUnits"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="PhoneNo">
                    <el-input v-model="FamilyaddForm.PhoneNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="FamilyaddFormVisible = false">取消</el-button>
                    <el-button type="primary"
                               v-on:click="FamilyaddSubmit('FamilyaddSubmit')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <externalOfferModal ref="externalOfferModal"></externalOfferModal>
    </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  getUserBaseInfoList,
  getUserBaseInfo,
  UpdateUserBaseInfo,
  getOrganizeList,
  AddInterview,
  getInterviewList,
  getInfoList,
  GetUserDis,
  AddProbation,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
    import UserChoose from "../../components/UserChoose";
    import externalOfferModal from "../componentModals/externalOfferModal"    // 应聘
export default {
        components: { Toolbar, UserChoose, externalOfferModal },
  data() {
      return {
          token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
      printObj: {
        id: "printMe",
        popTitle: "打印",
        // extraCss: 'https://www.google.com,https://www.google.com', // 用逗号分隔的附加链接连接
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>',
      },
      CustomeraddFormVisible: false,
      TrainingExperienceaddFormVisible: false,
      WorkExperienceaddFormVisible: false,
      FamilyaddFormVisible: false,
      UserVisible: false,
      loading: true,
      userInterviewID: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      RecordcurrentPage: 1, // 面试记录当前页码
      Recordtotal: null, // 面试记录总条数
      Recordtotaldata: null,
      RecordpageSize: 20, // 面试记录每页的数据条数
      Recordpage: 1,
      searchVal: "",
      filters: {
        name: "",
      },
      TrainaddForm: [],
      WorkaddForm: [],
      dataForm: [],
      choosedusers: [],
      addUserVisible: false,
      interviewVisible: false,
      userDetailVisible: false,
      permissionVisible: false,
      users: [],
      buttonList: [],
      userDetail: [],
      SalaryTypeLists: [],
      PositionLists: [],
      InterviewRecord: [],
      selectForm: {
        dateStart: "",
        dateEnd: "",
        name: "",
        idCard: "",
      },
      CustomeraddForm: [],
      FamilyaddForm: [],
      InterviewForm: {
        UserId: "",
        UserName: "",
        id: "",
        factory: "",
        post: "",
        talkDate: new Date(),
        isPassed: "",
        userBaseInfoId: "",
        factoryId: "",
        MatchRate: "",
        TalkPersons: "",
        Recommendation: "",
        BasicAbilityExp: "",
        BasicAbility: "",
        ProfessionalAbilityExp: "",
        ProfessionalAbility: "",
        OfficeAbilityExp: "",
        OfficeAbility: "",
        WorkAbility: "",
        WorkAbilityExp: "",
        Summarize: "",
        Remark: "",
        AttitudeExp: "",
        Attitude: "",
        DressUpExp: "",
        DressUp: "",
        PotentialAbilityExp: "",
        PotentialAbility: "",
        CommunicateAbilityExp: "",
        CommunicateAbility: "",
        ProfessionalSoftwareExp: "",
        ProfessionalSoftware: "",
      },
      PermissionForm: {
        userId: "",
        Post: "",
        PostId: "",
        StartDate: "",
        EndDate: "",
        SpecialSalary: "0",
        EateSalary: "",
        CareDaySalary: "0",
        OutsideSalary: "0",
        ProbationExplain: "",
        PositiveExplain: "",
        BusSalary: "",
        WorkSalary: "",
        FundsDeduct: "",
        DaySalary: "",
        BankCardName: "",
        BankAddress: "",
        BankCardNo: "",
        PositionId: "",
        PositionName: "",
        SalaryTypeName: "",
        SalaryTypeId: "",
        InterviewId: "",
      },
      qyTypeOptions: [
        {
          value: "未婚",
          label: "未婚",
        },
        {
          value: "已婚",
          label: "已婚",
        },
        {
            value: "离异",
            label: "离异",
        },
        {
            value: "丧偶",
            label: "丧偶",
        },
      ],
      edTypeOptions: [
         {
          value: "无",
          label: "无",
        },
        {
          value: "初中以下",
          label: "初中以下",
        },
        {
          value: "初中",
          label: "初中",
        },
        {
          value: "中专",
          label: "中专",
        },
        {
          value: "高中",
          label: "高中",
        },
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          value: "研究生",
          label: "研究生",
        },
        {
          value: "博士",
          label: "博士",
        },
        {
          value: "博士后",
          label: "博士后",
        },
      ],
        tiTypeOptions: [
            {
                value: "无",
                label: "无",
            },
            {
                value: "助理工程师",
                label: "助理工程师",
            },
            {
                value: "中级工程师",
                label: "中级工程师",
            },
            {
                value: "高级工程师",
                label: "高级工程师",
            },
            {
                value: "初级会计师",
                label: "初级会计师",
            },
            {
                value: "中级会计师",
                label: "中级会计师",
            },
            {
                value: "高级会计师",
                label: "高级会计师",
            },
            {
                value: "初级经济师",
                label: "初级经济师",
            },
            {
                value: "中级经济师",
                label: "中级经济师",
            },
            {
                value: "高级经济师",
                label: "高级经济师",
            },
        ],
        jnTypeOptions: [
            {
                value: "无",
                label: "无",
            },
            {
                value: "初级技工",
                label: "初级技工",
            },
            {
                value: "中级技工",
                label: "中级技工",
            },
            {
                value: "高级技工",
                label: "高级技工",
            },
            {
                value: "技师",
                label: "技师",
            },
            {
                value: "高级技师",
                label: "高级技师",
            },],
      poTypeOptions: [
        {
          value: "群众",
          label: "群众",
        },
        {
          value: "团员",
          label: "团员",
        },
        {
          value: "党员",
          label: "党员",
        },
        {
          value: "无党派人士",
          label: "无党派人士",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      adTypeOptions: [
        {
          value: "本市城镇",
          label: "本市城镇",
        },
        {
          value: "本市农村",
          label: "本市农村",
        },
        {
          value: "外省/市城镇",
          label: "外省/市城镇",
        },
        {
          value: "外省/市农村",
          label: "外省/市农村",
        },
      ],
      applyTypeOptions: [
        {
          value: "58同城",
          label: "58同城",
        },
        {
          value: "门口招聘栏",
          label: "门口招聘栏",
        },
        {
          value: "本公司员工介绍",
          label: "本公司员工介绍",
        },
        {
          value: "智联招聘",
          label: "智联招聘",
        },
        {
          value: "校园招聘会",
          label: "校园招聘会",
        },
        {
          value: "格瑞德官网",
          label: "格瑞德官网",
        },
        {
          value: "BOSS直聘",
          label: "BOSS直聘",
        },
        {
          value: "微信公众号",
          label: "微信公众号",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      OrganizeLists: [],
      positionLists: [
        {
          value: "技术",
          label: "技术",
        },
        {
          value: "后勤",
          label: "后勤",
        },
        {
          value: "生产",
          label: "生产",
        },
        {
          value: "管理",
          label: "管理",
        },
        {
          value: "特聘",
          label: "特聘",
        },
      ],
      passedLists: [
        {
          value: "通过",
          label: "通过",
        },
        {
          value: "未通过",
          label: "未通过",
        },
      ],
      EateSalaryLists: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "30",
          label: "30",
        },
      ],
      CareDaySalaryLists: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      OutsideSalaryLists: [
        {
          value: "300",
          label: "300",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      ProbationExplainLists: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "5",
          label: "5",
        },
      ],
      matchRateLists: [
        {
          value: "较高",
          label: "较高",
        },
        {
          value: "尚可",
          label: "尚可",
        },
        {
          value: "较低",
          label: "较低",
        },
        {
          value: "不符合",
          label: "不符合",
        },
      ],
      BasicLists: [
        {
          value: "较差",
          label: "较差",
        },
        {
          value: "一般",
          label: "一般",
        },
        {
          value: "良好",
          label: "良好",
        },
        {
          value: "较好",
          label: "较好",
        },
        {
          value: "优秀",
          label: "优秀",
        },
      ],
      RecommendationLists: [
        {
          value: "强烈推荐",
          label: "强烈推荐",
        },
        {
          value: "可安排复试",
          label: "可安排复试",
        },
        {
          value: "资料存档",
          label: "资料存档",
        },
        {
          value: "不符合",
          label: "不符合",
        },
      ],
      rules: {
        factory: [
          { required: true, message: "请选择面试公司", trigger: "blur" },
        ],
        talkDate: [{ required: true, message: "请选择日期", trigger: "blur" }],
        post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        isPassed: [
          { required: true, message: "请选择是否通过", trigger: "blur" },
        ],
        PostType: [
          { required: true, message: "请选择岗位类型", trigger: "blur" },
        ],
        TalkPersons: [
          { required: true, message: "请选择面试人员", trigger: "blur" },
        ],
        BasicAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        ProfessionalAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        WorkAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        OfficeAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        PotentialAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        DressUpExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
          Summarize: [{ required: true, message: "请输入内容", trigger: "blur" }],
          OldFactoryWelfare: [{ required: true, message: "前公司福利待遇", trigger: "blur" }],
          OldFactory: [{ required: true, message: "前公司名称", trigger: "blur" }],
          
        AttitudeExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        ProfessionalSoftwareExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        CommunicateAbilityExp: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
      postRules: {
        Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        StartDate: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        ProbationExplain: [
          { required: true, message: "请选择试用期", trigger: "blur" },
        ],
        PositionName: [
          { required: true, message: "请选择职务待遇", trigger: "blur" },
        ],
        SalaryTypeName: [
          { required: true, message: "请选择工资类型", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
     FamilyDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.userDetail.FamilyArr.splice(this.userDetail.FamilyArr.indexOf(row), 1);
      });
    },
     WorkExperienceDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.userDetail.WorkedArr.splice(this.userDetail.WorkedArr.indexOf(row), 1);
      });
    },
    TrainingExperienceDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.userDetail.TrainedArr.splice(this.userDetail.TrainedArr.indexOf(row), 1);
      });
    },
       CustomerhandleDelete(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.userDetail.EducatedArr.splice(this.userDetail.EducatedArr.indexOf(row), 1);
      });
    },
    print() {
      this.$print(this.$refs.print);
      },
      
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    // handleCurrentChange(val) {
    //         this.page = val;
    //         this.getUsers(isCurrent = false);
    //     },
    //获取用户列表
    getUsers(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        name: this.selectForm.name,
        dateStart: this.selectForm.dateStart,
        dateEnd: this.selectForm.dateEnd,
        idCard: this.selectForm.idCard,
      };
     
 this.$api.getUserBaseInfoList(para).then((res) => {
        console.log(res.data.response.data);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
       
    },
    getInterview() {
      let para = {
        userBaseInfoId: this.userInterviewID,
      };
      console.log(para);
      getInterviewList(para).then((res) => {
        this.InterviewRecord = res.data.response;
      });
    },

    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getUsers(false);
    },
    Print() {
      let row = this.currentRow;
      if (!row) {
         this.$router.push({ path: "/printUser", query: { id: 0 } });
         return;
      }
      this.$router.push({ path: "/printUser", query: { id: row.Id } });
    },

    //显示编辑界面
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
        }
        this.$refs.externalOfferModal.onShowDlg(row.Id);

      //this.userInterviewID = row.Id;
      //let para = {
      //  id: row.Id,
      //};
      //getUserBaseInfo(para).then((res) => {
      //  this.userDetail = res.data.response;
      //  this.userDetailVisible = true;
      //});
      //this.getInterview();
      },
      
    //添加试用
    addProbation() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.InterviewIsPassed != "通过") {
        this.$message({
          message: "没有面试信息或面试未通过！",
          type: "error",
        });
        return;
      }
      console.log(row);
      this.PermissionForm.InterviewId = row.InterviewId;
      this.PermissionForm.userId = row.UserId;
      getInfoList().then((res) => {
        this.SalaryTypeLists = res.data.response;
      });
      GetUserDis().then((res) => {
        this.PositionLists = res.data.response;
      });
      this.permissionVisible = true;
    },
    //添加面试
    addInterview() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
        this.InterviewForm.UserId = "";
        this.InterviewForm.UserName = "";
        this.InterviewForm.id = "";
        this.InterviewForm.factory = "";
        this.InterviewForm.post = "";
        this.InterviewForm.talkDate = new Date();
        this.InterviewForm.isPassed = "";
        this.InterviewForm.userBaseInfoId = "";
        this.InterviewForm.factoryId = "";
        this.InterviewForm.MatchRate = "";
        this.InterviewForm.TalkPersons = "";
        this.InterviewForm.Recommendation = "";
        this.InterviewForm.BasicAbilityExp = "";
        this.InterviewForm.BasicAbility = "";
        this.InterviewForm.ProfessionalAbilityExp = "";
        this.InterviewForm.ProfessionalAbility = "";
        this.InterviewForm.OfficeAbilityExp = "";
        this.InterviewForm.OfficeAbility = "";
        this.InterviewForm.WorkAbility = "";
        this.InterviewForm.WorkAbilityExp = "";
        this.InterviewForm.Summarize = "";
        this.InterviewForm.Remark = "";
        this.InterviewForm.AttitudeExp = "";
        this.InterviewForm.Attitude = "";
        this.InterviewForm.DressUpExp = "";
        this.InterviewForm.DressUp = "";
        this.InterviewForm.PotentialAbilityExp = "";
        this.InterviewForm.PotentialAbility = "";
        this.InterviewForm.CommunicateAbilityExp = "";
        this.InterviewForm.CommunicateAbility = "";
        this.InterviewForm.ProfessionalSoftwareExp = "";
        this.InterviewForm.ProfessionalSoftware = "";
      console.log(row);
      (this.InterviewForm.userBaseInfoId = row.Id),
        (this.InterviewForm.UserId = row.UserId);
        this.InterviewForm.UserName = row.Name;
        let para;
        para = {
            deptTypeName: "公司",
            workTypeName: "后勤"
        };
        getOrganizeList(para).then((res) => {
        this.OrganizeLists = res.data.data;
        this.interviewVisible = true;
      });
    },
    //监听子组件事件
    usercallFunction(newdata) {
      console.log(newdata);
      var userStr = "";
      for (var i = 0; i < newdata.length; i++) {
        userStr += newdata[i].UserNameClaim + " ";
      }
      this.InterviewForm.TalkPersons = userStr;
      this.addUserVisible = false;
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    //头像上传成功
    OSOnSuccess(response, file, fileList) {
      this.userDetail.Image_URL =
          response.data.httpHost + response.data.filePath;
    },
    //头像上传附件数量控制
    OSFileexceed(files, fileList) {
      this.$message({
        message: "当前上传最大数量为1个。",
        type: "warning",
      });
    },
    submitForm(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        let para = Object.assign({}, this.userDetail);
        console.log(this.userDetail);
        UpdateUserBaseInfo(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.userDetailVisible = false;
            this.getUsers();
          }
        });
      });
    },
    onPermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.PermissionForm);
          console.log(para);
          // AddProbation(para).then(res => {
          // 	console.log(res)
          //     if (res.data.success) {
          //        this.$message({
          //             message: '添加成功',
          //             type: 'success',
          //             duration: 5000
          //         });
          //     this.permissionVisible = false;

          //     }else{
          // 		 this.$message({
          //             message: res.data.msg,
          //             type: 'error',
          //             duration: 5000
          //         });
          // 	}
          // });
          this.getUsers();
        });
      });
    },
    selectModel(id) {
      let obj = {};
      obj = this.OrganizeLists.find((item) => {
        //model就是上面的数据源

        return item.Name === id; //筛选出匹配数据
      });
      this.InterviewForm.factoryId = obj.Id;
    },
    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源

        return item.LeaveName === id; //筛选出匹配数据
      });
      this.PermissionForm.PositionId = obj.Id;
    },
    SalaryTypeModel(id) {
      var obj = {};
      obj = this.SalaryTypeLists.find((item) => {
        //model就是上面的数据源

        return item.Type === id; //筛选出匹配数据
      });
      this.PermissionForm.SalaryTypeId = obj.Id;
    },
    ProbationExplainModel(id) {
      var starttime = this.PermissionForm.StartDate;
      if (starttime.length == 0) {
        this.$message({
          message: "请先选择开始时间",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm.ProbationExplain = "";
      } else {
        let n = parseInt(id);
        let d = new Date(starttime);
        let year = d.getFullYear();
        let mon = d.getMonth() + 1 + n;
        let day = d.getDate();
        this.PermissionForm.EndDate = year + "-" + mon + "-" + day;
      }
    },
      onSubmit(formName) {
          if (this.InterviewForm.factory == "") {
              this.$message({
                  message: "请填写面试公司",
                  type: "error",
                  duration: 5000,
              });
              return;
          }
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.InterviewForm);
          AddInterview(para).then((res) => {
            console.log(para);
            console.log(res);
            if (res.data.success) {
              this.$message({
                message: "提交成功",
                type: "success",
                duration: 5000,
              });
              this.interviewVisible = false;
              this.getUsers();
            } else {
              this.$message({
                message: "添加失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },

    resetForm(formName) {
      this.userDetailVisible = false;
    },
    phonecheck() {
      if (!validate.isMobile(this.form.MobilePhone)) {
        this.$message({
          message: "该输入无效,请输入其他有效手机号",
          type: "error",
          duration: 5000,
        });
        this.form.MobilePhone = null;
        return;
      }
    },
    nocheck() {
      if (!validate.validateIdNo(this.form.idCard)) {
        this.$message({
          message: "该输入无效,请输入有效身份证号",
          type: "error",
          duration: 5000,
        });
        this.form.idCard = null;
        return;
      }
    },
    //学习经历添加提交
    CustomeraddSubmit(formName) {
      let _this = this;
      this.$refs.CustomeraddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.CustomeraddForm);
          if (this.CustomeraddForm.rowIndex != undefined) {
            this.$set(
              this.userDetail.EducatedArr,
              this.CustomeraddForm.rowIndex,
              para
            );
          } else {
            this.userDetail.EducatedArr.push(para);
          }

          this.CustomeraddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    CustomerhandleDetail(row, index) {
      this.CustomeraddForm = Object.assign({}, row);
      this.CustomeraddForm.rowIndex = index;
      this.CustomeraddFormVisible = true;
    },
    //学习经历添加
    customerhandleAdd() {
      this.CustomeraddFormVisible = true;
      this.CustomeraddForm = {
        EnterTime: null,
        GraduateTime: null,
        GradauteSchool: null,
        Speciality: null,
        Certificate: null,
        Voucher: null,
      };
      this.$nextTick(() => {
        this.$refs.CustomeraddForm.clearValidate();
      });
    },
    trainAdd() {
      this.TrainingExperienceaddFormVisible = true;

      this.TrainaddForm = {
        StartTime: null,
        EndTime: null,
        Institutions: null,
        Details: null,
        InCertificate: null,
      };
      this.$nextTick(() => {
        this.$refs.TrainaddForm.clearValidate();
      });
    },
    TrainingExperienceDetail(row, index) {
      this.TrainaddForm = Object.assign({}, row);
      this.TrainaddForm.rowIndex = index;
      this.TrainingExperienceaddFormVisible = true;
    },
    TrainaddSubmit(formName) {
      let _this = this;

      let para = Object.assign({}, this.TrainaddForm);
      console.log(this.TrainaddForm);
      if (this.TrainaddForm.rowIndex != undefined) {
        this.$set(this.userDetail.TrainedArr, this.TrainaddForm.rowIndex, para);
      } else {
        this.userDetail.TrainedArr.push(para);
      }

      this.TrainingExperienceaddFormVisible = false;
    },
    workAdd() {
      this.WorkExperienceaddFormVisible = true;
      this.WorkaddForm = {
        StartTime: null,
        EndTime: null,
        WorkAddress: null,
        LeavingReason: null,
        BecaseWork: null,
        WorkVoucher: null,
      };
      this.$nextTick(() => {
        this.$refs.WorkaddForm.clearValidate();
      });
    },
      WorkaddSubmit(formName) {
          
      let _this = this;
      this.$refs.WorkaddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.WorkaddForm);
          if (this.WorkaddForm.rowIndex != undefined) {
            this.$set(
              this.userDetail.WorkedArr,
              this.WorkaddForm.rowIndex,
              para
            );
          } else {
              
            this.userDetail.WorkedArr.push(para);
          }

          this.WorkExperienceaddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },

    WorkExperienceDetail(row, index) {
      this.WorkaddForm = Object.assign({}, row);
      this.WorkaddForm.rowIndex = index;
      this.WorkExperienceaddFormVisible = true;
    },
    familyAdd() {
      this.FamilyaddFormVisible = true;
      this.FamilyaddForm = {
        FaName: null,
        Relationship: null,
        WorkDpt: null,
        WorkUnits: null,
        PhoneNo: null,
      };
      this.$nextTick(() => {
        this.$refs.FamilyaddForm.clearValidate();
      });
    },
    FamilyaddSubmit(formName) {
      let _this = this;
      this.$refs.FamilyaddForm.validate((vaild) => {
        if (vaild) {
          let para = Object.assign({}, this.FamilyaddForm);
          if (this.FamilyaddForm.rowIndex != undefined) {
            this.$set(
              this.userDetail.FamilyArr,
              this.FamilyaddForm.rowIndex,
              para
            );
          } else {
            this.userDetail.FamilyArr.push(para);
          }

          this.FamilyaddFormVisible = false;
        } else {
          this.$message({
            message: "请填写完整数据!",
            type: "error",
          });
        }
      });
    },
    FamilyDetail(row, index) {
      this.FamilyaddForm = Object.assign({}, row);
      this.FamilyaddForm.rowIndex = index;
      this.FamilyaddFormVisible = true;
    },
  },
  mounted() {
    this.getUsers();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
    console.log(this.buttonList);
  },
};
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-form-item__content {
  margin-left: 10%;
}
</style>

